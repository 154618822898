// Derived knowledge types

import { capitalize, isString, last } from "lodash";
import useKnowledge from "../composables/useKnowledge";
import { COMPOSITE_PROPERTY_VALUE_TYPE, PropertyKnowledgeRef, PropertyType } from "./knowledge";
import { QueryPathNode } from "./query";
import { GraphValueType } from "./value";

export enum PropertyOpType {
  Sum = "sum",
  Max = "max",
  Min = "min",
  Avg = "avg",
  Median = "median",
  Percentile = "percentile",
  Add = "add",
  Subtract = "subtract",
  DateDiff = "datetime_diff",
  DateTrunc = "date_trunc",
  Divide = "divide",
  Multiply = "multiply",
  Count = "count",
  Ntile = "ntile",
}

export const AGGREGATE_OP_TYPES = [
  PropertyOpType.Sum,
  PropertyOpType.Max,
  PropertyOpType.Min,
  PropertyOpType.Avg,
  PropertyOpType.Median,
  PropertyOpType.Percentile,
  PropertyOpType.Ntile,
  PropertyOpType.Count,
];

export interface BaseDerivedPropertyType {
  op: PropertyOpType;
}

export interface SumPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Sum;
  property_type: PropertyKnowledgeRef; // Should be a Term?
}

export interface AvgPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Avg;
  property_type: PropertyKnowledgeRef; // Should be a Term?
}

export interface MedianPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Median;
  property_type: PropertyKnowledgeRef; // Should be a Term?
}

export interface PercentilePropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Percentile;
  property_type: PropertyKnowledgeRef; // Should be a Term?
  percentage: number;
  approx: boolean;
}

export interface MaxPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Max;
  property_type: PropertyKnowledgeRef; // Should be a Term?
}

export interface MinPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Min;
  property_type: PropertyKnowledgeRef; // Should be a Term?
}

export interface AddPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Add;
  terms: DerivedPropertyTerm[];
}

export interface SubtractPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Subtract;
  terms: DerivedPropertyTerm[];
}

export interface DividePropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Divide;
  divisor: DerivedPropertyTerm;
  dividend: DerivedPropertyTerm;
}

export type TimeUnit =
  | "MICROSECOND"
  | "MILLISECOND"
  | "SECOND"
  | "MINUTE"
  | "HOUR"
  | "DAY"
  | "WEEK"
  | "MONTH"
  | "QUARTER"
  | "YEAR";

export const dateDiffUnitOptions: TimeUnit[] = [
  "MICROSECOND",
  "MILLISECOND",
  "SECOND",
  "MINUTE",
  "HOUR",
  "DAY",
  "WEEK",
  "MONTH",
  "QUARTER",
  "YEAR",
];

export interface DateDiffPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.DateDiff;
  unit: TimeUnit;
  start: DerivedPropertyTerm;
  end: DerivedPropertyTerm;
}

export interface MultiplyPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Multiply;
  factors: DerivedPropertyTerm[];
}

export interface CountPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Count;
  approx: boolean;
}

export interface NtilePropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.Ntile;
  property_type: PropertyKnowledgeRef; // Should be a Term?
  n: number;
}

export interface DateTruncPropertyType extends BaseDerivedPropertyType {
  op: PropertyOpType.DateTrunc;
  property_type: PropertyKnowledgeRef; // Should be a Term?
  bucket_size: TimeUnit;
}

export type DerivedPropertyType =
  | SumPropertyType
  | AvgPropertyType
  | MedianPropertyType
  | PercentilePropertyType
  | MaxPropertyType
  | MinPropertyType
  | AddPropertyType
  | SubtractPropertyType
  | DividePropertyType
  | DateDiffPropertyType
  | DateTruncPropertyType
  | MultiplyPropertyType
  | CountPropertyType
  | NtilePropertyType;

export type DerivedPropertyTerm = DerivedPropertyType | PropertyKnowledgeRef;

// Mines the raw property type knowledge references from a derived property
export function underlyingPropertyTypes(dpt: DerivedPropertyTerm): PropertyKnowledgeRef[] {
  if (isString(dpt)) return [dpt];
  switch (dpt.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Percentile:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.Ntile:
    case PropertyOpType.DateTrunc:
      return [dpt.property_type];
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
      return dpt.terms.flatMap(underlyingPropertyTypes);
    case PropertyOpType.Multiply:
      return dpt.factors.flatMap(underlyingPropertyTypes);
    case PropertyOpType.Divide:
      return [dpt.dividend, dpt.divisor].flatMap(underlyingPropertyTypes);
    case PropertyOpType.DateDiff:
      return [dpt.start, dpt.end].flatMap(underlyingPropertyTypes);
    case PropertyOpType.Count:
      return [];
  }
}

// Most derived property ops return values of the same type as that of the
// operand property types. Some (count, stddev, etc.) will return a fixed value
// type instead. This function will return null if the derived property value
// type matches the operand. Otherwise, it'll return a value type.
export function valueTypeOfDerivedProperty(dpt: DerivedPropertyType): GraphValueType | null {
  switch (dpt.op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Percentile:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
    case PropertyOpType.Multiply:
    case PropertyOpType.Ntile:
      return null;
    case PropertyOpType.Divide:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
      return GraphValueType.Float;
    case PropertyOpType.Count:
    case PropertyOpType.DateDiff:
      return GraphValueType.Integer;
    case PropertyOpType.DateTrunc:
      return GraphValueType.Datetime;
  }
}

export function validDerivedPropertyTermType(
  op: PropertyOpType,
  valueType: GraphValueType | typeof COMPOSITE_PROPERTY_VALUE_TYPE
): boolean {
  if (valueType === COMPOSITE_PROPERTY_VALUE_TYPE) return false;
  switch (op) {
    case PropertyOpType.Sum:
    case PropertyOpType.Avg:
    case PropertyOpType.Median:
    case PropertyOpType.Percentile:
    case PropertyOpType.Min:
    case PropertyOpType.Max:
    case PropertyOpType.Add:
    case PropertyOpType.Subtract:
    case PropertyOpType.Multiply:
    case PropertyOpType.Divide:
    case PropertyOpType.Ntile:
      return [GraphValueType.Float, GraphValueType.Integer].includes(valueType);
    case PropertyOpType.DateDiff:
    case PropertyOpType.DateTrunc:
      return [GraphValueType.Datetime, GraphValueType.Date].includes(valueType);
    case PropertyOpType.Count:
      return false;
  }
}

export function propertyName(
  propType: DerivedPropertyTerm,
  path?: QueryPathNode[],
  displayName?: string
): string {
  const { typeLabel } = useKnowledge();
  let name: string;
  if (displayName) {
    return displayName;
  } else if (isString(propType)) {
    name = typeLabel(propType);
  } else {
    switch (propType.op) {
      case PropertyOpType.Sum:
        name = `Total ${propertyName(propType.property_type)}`;
        break;
      case PropertyOpType.Avg:
        name = `Average ${propertyName(propType.property_type)}`;
        break;
      case PropertyOpType.Median:
        name = `Median ${propertyName(propType.property_type)}`;
        break;
      case PropertyOpType.Percentile:
        name = `${propType.percentage} Percentile ${propertyName(propType.property_type)}`;
        break;
      case PropertyOpType.Min:
        name = `Minimum ${propertyName(propType.property_type)}`;
        break;
      case PropertyOpType.Max:
        name = `Maximum ${propertyName(propType.property_type)}`;
        break;
      case PropertyOpType.Add:
        name = propType.terms.map((p) => propertyName(p)).join(" + ");
        break;
      case PropertyOpType.Subtract:
        name = propType.terms.map((p) => propertyName(p)).join(" - ");
        break;
      case PropertyOpType.Multiply:
        name = propType.factors.map((p) => propertyName(p)).join(" × ");
        break;
      case PropertyOpType.Divide:
        name = `${propertyName(propType.dividend)} / ${propertyName(propType.divisor)}`;
        break;
      case PropertyOpType.DateDiff:
        name = `${capitalize(propType.unit)}s(${propertyName(propType.end)} - ${propertyName(propType.start)})`;
        break;
      case PropertyOpType.DateTrunc:
        name = `${propertyName(propType.property_type)} ${capitalize(propType.bucket_size)}`;
        break;
      case PropertyOpType.Count:
        name = "Count";
        break;
      case PropertyOpType.Ntile:
        name = `${propertyName(propType.property_type)} ${propType.n}tile`;
    }
  }
  if (path != null) {
    const neighborConcept = last(path)!.concept_type;
    return `${typeLabel(neighborConcept)} ${name}`;
  }
  return name;
}

export function propertyValueType(dpt: DerivedPropertyTerm) {
  const { getKnowledgeItem } = useKnowledge();
  const prop = underlyingPropertyTypes(dpt)[0];
  if (isString(dpt)) {
    return (getKnowledgeItem(prop) as PropertyType).value_type!;
  } else {
    return valueTypeOfDerivedProperty(dpt) ?? (getKnowledgeItem(prop) as PropertyType).value_type!;
  }
}

// Suggests the value type for filters created on the underlying property
export function filterValueType(dpt: DerivedPropertyTerm) {
  const { getKnowledgeItem } = useKnowledge();
  const prop = underlyingPropertyTypes(dpt)[0];
  return (getKnowledgeItem(prop) as PropertyType).value_type!;
}
